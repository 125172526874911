import {
  OPEN,
  CLOSE,
  SET_PAYLOAD,
  SET_ERROR,
  SET_PAYLOAD_COMPLETED,
  CLEAR
} from "./constant";

const initialState = {
  isOpen: false,
  payload: {},
  error: {},
  completed: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        isOpen: true
      };
    case CLOSE:
      return {
        ...state,
        isOpen: false
      };
    case SET_PAYLOAD:
      return {
        ...state,
        payload: action.payload
      };
    case SET_PAYLOAD_COMPLETED:
      return {
        ...state,
        payload: action.payload,
        completed: true
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        completed: true
      };
    case CLEAR:
      return initialState;

    default:
      return state;
  }
}
