import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { isSignedInSelector } from "../selector";
import { renewSession } from "../thunk";
import { configSelector } from "../../Config/selector";

/**
 * ONLY check session for INTERNAL apps.
 * Signs a user into app if session is valid and renew session time.
 */
const RenewSession = ({ children }) => {
  const [running, setRunning] = useState(true);
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => isSignedInSelector(state));
  const config = useSelector(state => configSelector(state));

  useEffect(() => {
    if (config.x_cc_app === undefined && isSignedIn === false) {
      const renewed = dispatch(renewSession());

      Promise.resolve(renewed).then(() => setRunning(false));
    } else {
      setRunning(false);
    }
  }, [isSignedIn]);

  if (running === true) return null;
  if (running === false) return children;
};

RenewSession.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default RenewSession;
