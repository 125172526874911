import { LOAD_LINKS } from "./constant";

const initialState = {
  urls: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_LINKS:
      return {
        ...state,
        urls: action.payload
      };

    default:
      return state;
  }
};
