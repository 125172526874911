import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore from "./store";
import Modal from "react-modal";

import { openWidget, closeWidget } from "./modules/Widget";
import SessionExpireWarning from "./modules/Security/Components/sessionExpireWarning";
import CheckSession from "./modules/Security/Components/checkSession";

/**
 * builds the new redex store
 * @param {object} config
 * @param {object} reducers
 * @returns history, store
 */
const getStore = (config, reducers) => {
  const { history, store } = configureStore(config, reducers);

  return { history, store };
};

/**
 * Layout.
 * builds default application template
 *
 * @param {Object} store
 * @param {Object} history
 * @param {JSX} App
 */
const Layout = (store, history, App) => () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <CheckSession>
          <SessionExpireWarning />
          <App />
        </CheckSession>
      </ConnectedRouter>
    </Provider>
  );
};

/**
 * Renders the website.
 * renders the App to the page to a container of root
 * setups the redux store, and renders the App to the page
 * @param {Object} config
 * @param {Object} reducers
 * @param {JSX} App
 */
export const renderSite = (config, reducers, App) => {
  const { store, history } = getStore(config, reducers);
  const Root = Layout(store, history, App);
  Modal.setAppElement(document.getElementById("root"));
  render(<Root />, document.getElementById("root"));
};

/**
 * Renders the Widget & setups communication with the parent.
 *
 * setups the redux store, and renders the App to the page
 *
 * sends the parent an event of type "cardcash-loaded" to inform
 * the parent the widget is ready for use.
 *
 * sends the parent an event of type "cardcash-complete" when the action is finished successful.
 * sends the parent an event of type "cardcash-error" when an error has occurred.
 *
 * @param {Object} config
 * @param {Object} reducers
 * @param {JSX} App
 */
export const renderWidget = (config, reducers, App) => {
  const { store, history } = getStore(config, reducers);
  const Root = Layout(store, history, App);
  Modal.setAppElement(document.getElementById("root"));
  render(<Root />, document.getElementById("root"));

  document.addEventListener("DOMContentLoaded", (event) => {
    window.parent.postMessage({ type: "cardcash-loaded" }, "*");
  });

  const EVENT_METHOD = window.addEventListener
    ? "addEventListener"
    : "attachEvent";

  window[EVENT_METHOD](
    EVENT_METHOD === "attachEvent" ? "onmessage" : "message",
    (event) => {
      if (event.data.type === "cardcash-open") {
        openWidget(event.data.payload, store)
          .then((response) => {
            window.parent.postMessage(
              { type: "cardcash-complete", payload: response },
              "*"
            );
          })
          .catch((error) => {
            window.parent.postMessage(
              { type: "cardcash-error", payload: error },
              "*"
            );
          });
      }

      if (event.data.type === "cardcash-close") {
        store.dispatch(closeWidget());
      }
    }
  );
};

export { defaultConfig, configBuilder, configSelector } from "./modules/Config";

export { InternalHeader } from "./modules/Header";

export { remoteSelector, isLoadingSelector, remote } from "./modules/Remote";

export { PrivateRoute, PublicRoute } from "./modules/Router";

export {
  SecuritySelector,
  SecurityRouter,
  isSignedInSelector,
  UserSelector,
  signedIn,
  logout,
} from "./modules/Security";

export { widgetSelector, closeWidget, openWidget } from "./modules/Widget";

export { Icon, score, filter, NullMerchant, NullMerchants } from "./utils";
