import React, { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Countdown from "react-countdown";

import { SecuritySelector } from "../selector";
import { showSessionExpiredModal } from "../action";
import { renewSession, doLogout, redirectToLogin } from "../thunk";
import "./sessionExpireWarning.css";

export default () => {
  let currentTimeout;
  const dispatch = useDispatch();
  const security = useSelector(state => SecuritySelector(state));
  const timetoCancelInMS = 60000;

  const loadTrigger = () => {
    killTrigger();

    currentTimeout = setTimeout(() => {
      dispatch(showSessionExpiredModal(false));
      dispatch(doLogout());
      dispatch(redirectToLogin());
    }, timetoCancelInMS);
  };

  const killTrigger = () => {
    if (currentTimeout !== undefined) {
      clearTimeout(currentTimeout);
    }
  };

  const newSession = () => {
    killTrigger();
    dispatch(renewSession());
    dispatch(showSessionExpiredModal(false));
  };

  useEffect(() => {
    if (security.showSessionExpiredModal) {
      loadTrigger();
    }

    return () => {
      killTrigger();
    };
  }, [security.showSessionExpiredModal]);

  return (
    <Modal
      isOpen={security.showSessionExpiredModal}
      contentLabel="Session Expire Warning"
    >
      <h1 className="session-text-center">Your Session is about to expire</h1>
      <p className="session-text-center">
        You have been inactive for too long. For your protection, you will have
        to renew your session. If you don't address this warning in &nbsp;
        <Countdown date={Date.now() + timetoCancelInMS} />, your session will be
        expired and you will be logged out.
      </p>
      <p>
        <button className="session-submit" onClick={newSession}>
          Renew session
        </button>
      </p>
    </Modal>
  );
};
