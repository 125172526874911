import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import configReducer from "./modules/Config/reducer";
import securityReducer from "./modules/Security/reducer";
import remoteReducer from "./modules/Remote/reducer";
import headerReducer from "./modules/Header/reducer";
import widgetReducer from "./modules/Widget/reducer";

export default (history, reducers) =>
  combineReducers({
    ...reducers,
    remote: remoteReducer,
    config: configReducer,
    security: securityReducer,
    header: headerReducer,
    widget: widgetReducer,
    router: connectRouter(history)
  });
