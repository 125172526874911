import axios from "axios";
import { setLoading } from "./action";
import { redirectToLogin } from "../Security/thunk";

/** window.localStorage */
const STORAGE = window.localStorage;

/** localStorage Key */
const STORAGE_KEY = "__cardcash_storage__";

/**
 * get item from storage
 *
 * @returns Object
 */
const getStorageItem = () => {
  return JSON.parse(STORAGE.getItem(STORAGE_KEY)) || {};
};

/**
 * set item in storage
 *
 * @param {object} item cardcash session
 */
const setStorageItem = (item) => {
  STORAGE.setItem(STORAGE_KEY, JSON.stringify(item));
};

/**
 * update item in storage
 *
 * @param {object} item cardcash session
 */
const updateStorageItem = (key, value) => {
  const storage = getStorageItem();
  const newStorage = Object.assign({}, storage);
  newStorage[key] = value;
  newStorage.updated = new Date().getTime();
  setStorageItem(newStorage);
};

/**
 * is the current configurations a public CC site
 */
const isPublicAPI = (appId) => appId !== "";

/**
 * set the configuration request object
 *
 * @param {String} baseURL - HTTP base uri
 * @param {(String)} appId - optional appId
 * @param {Boolean} sessionTracker - sessionTracker on/off
 * @param {(String)} sessionDispatchType - optional session event type - required when sessionTracker = true
 * @param {(String)} sessionUrl - optional session url, the url to track - required when sessionTracker = true
 */
export const init = (
  baseURL,
  appId,
  sessionTracker,
  sessionDispatchType,
  sessionUrl
) => {
  const currentStorage = getStorageItem();
  const ignoreKeys = (key, val) => {
    const IGNORE_KEY_LIST = [
      "created",
      "updated",
      "__session__",
      "sessionTimeout",
    ];
    if (!IGNORE_KEY_LIST.includes(key)) return val;
  };

  const newStorage = {
    apiHost: baseURL,
    appId,
    sessionTracker,
    sessionDispatchType,
    sessionUrl,
    created: new Date().getTime(),
    updated: "",
    sessionTimeout: null,
  };

  if (
    JSON.stringify(newStorage, ignoreKeys) !==
    JSON.stringify(currentStorage, ignoreKeys)
  ) {
    setStorageItem(newStorage);
  }
};

/**
 * Send HTTP request
 *
 * @async
 * @param {String} method - HTTP request methods
 * @param {String} path - URL Path
 * @param {(Object | null)} body - HTTP request body object
 * @param {String} description - description of HTTP request
 * @param {Dispatch} dispatch - React Redux Dispatch function
 * @returns {Promise} Promise object represents the Response or Error provided from server
 */
export const remote = async (method, path, body, description, dispatch) => {
  const storage = getStorageItem();

  if (Object.entries(storage).length === 0) {
    throw new Error(`${STORAGE_KEY} is empty!`);
  }

  try {
    dispatch(setLoading(true));

    const {
      apiHost,
      appId,
      sessionTracker,
      sessionTimeout,
      sessionDispatchType,
      __session__,
    } = storage;

    const axiosConfig = {
      baseURL: apiHost,
      timeout: 180000, // 3 minute timeout
      responseType: "json",
      withCredentials: true,
      headers: {},
    };

    // add x-cc-app to public api calls
    if (isPublicAPI(appId)) {
      axiosConfig.headers["x-cc-app"] = appId;
    }

    // add Authorization to public api calls, if exists
    if (__session__?.token) {
      axiosConfig.headers["Authorization"] = `Bearer ${__session__?.token}`;
    }

    const newInstance = axios.create(axiosConfig);
    const response = await newInstance[method](path, body);
    const { data, headers } = response;

    // Track session
    if (sessionTracker) {
      // handle session expire refresh
      if (headers && "x-token-exp-seconds" in headers) {
        if (sessionTimeout) {
          clearTimeout(sessionTimeout);
        }

        updateStorageItem(
          "sessionTimeout",
          setTimeout(() => {
            dispatch({ type: sessionDispatchType });
          }, (headers["x-token-exp-seconds"] - 300) * 1000)
        );
      }

      // handle logout session expire
      if (
        path.toLowerCase().includes("logout") &&
        isPublicAPI(appId) &&
        sessionTimeout
      ) {
        clearTimeout(sessionTimeout);
      }
    }

    // check all response headers objects for authorization
    // so we can automatically hand it in subsequent calls
    if (headers && "authorization" in headers) {
      updateStorageItem("__session__", {
        id: headers["x-session-id"],
        token: headers.authorization,
        created: new Date().getTime(),
      });
    }

    dispatch(setLoading(false));

    return data;
  } catch (error) {
    const { appId, sessionUrl } = storage;
    const {
      message,
      response: { data, status },
    } = error;

    console.error(`Error: ${description}`, message, new Date());

    dispatch(setLoading(false));

    // no public cc session, lets create it!
    if (
      isPublicAPI(appId) &&
      message === "CardCash session token is missing."
    ) {
      remote("post", sessionUrl, {}, "create session", dispatch);
    }

    if (status === 401 && !isPublicAPI(appId)) {
      dispatch(redirectToLogin());
    }

    return data;
  }
};
