import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Icon } from "../../../utils/";
import { UserSelector } from "../../Security/selector";

export default () => {
  const user = useSelector((state) => UserSelector(state));
  const [menu, setMenu] = useState(false);
  const [upVee, setUpVee] = useState("hide");
  const [downVee, setDownVee] = useState("show");

  const toggle = () => {
    menu === true ? setMenu(false) : setMenu(true);

    if (upVee === "hide") {
      setUpVee("show");
      setDownVee("hide");
    } else {
      setUpVee("hide");
      setDownVee("show");
    }
  };

  return (
    <div className="accountInfo">
      <div className="accountName">{(user && user.adminName) || ""}</div>
      <svg className="vee" height="20" width="20" onClick={toggle}>
        <line
          className={downVee}
          x1="2"
          y1="2"
          x2="10"
          y2="10"
          stroke="#529fca"
        />
        <line
          className={downVee}
          x1="10"
          y1="10"
          x2="18"
          y2="2"
          stroke="#529fca"
        />
        <line
          className={upVee}
          x1="2"
          y1="10"
          x2="10"
          y2="2"
          stroke="#529fca"
        />
        <line
          className={upVee}
          x1="10"
          y1="2"
          x2="18"
          y2="10"
          stroke="#529fca"
        />
      </svg>
      <div className={`menu ${menu === true ? "menu-open" : "menu-close"}`}>
        <div className="item">
          <Icon name={"fa-user"} />

          <Link to={"/security/logout"}> Logout </Link>
        </div>
      </div>
    </div>
  );
};
