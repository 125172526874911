import React, { useState, useEffect, useRef } from "react";

export default (initialState, validation) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const latestSubmittingState = useRef(true);
  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const isAllFieldsValid = (values) => {
    let valid = true;

    for (let key in values) {
      if (values[key].length) {
        valid = false;
        break;
      }
    }

    return valid;
  };

  useEffect(() => {
    if (
      JSON.stringify(values, null, 2) !== JSON.stringify(initialState, null, 2)
    ) {
      setErrors({
        ...errors,
        ...validation(values),
      });

      if (isAllFieldsValid(errors)) {
        latestSubmittingState.current = false;
      }
    }
  }, [values]);

  let isSubmitting = latestSubmittingState.current;
  let isValid = !latestSubmittingState.current;

  return [values, errors, isSubmitting, isValid, onChange];
};
