import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import BurgerMenu from "./BurgerMenu";
import AccountInfo from "./AccountInfo";
import { isSignedInSelector } from "../../Security/selector";

import "./header.css";

const Internal = ({ title }) => {
  const isSignedIn = useSelector(state => isSignedInSelector(state));

  return isSignedIn === true ? (
    <div className="cc-header">
      <BurgerMenu />
      <h1><Link className="header-link" to="/">{title}</Link></h1>
      <AccountInfo />
    </div>
  ) : null;
};

Internal.propTypes = {
  title: PropTypes.string.isRequired
};

export default Internal;
