import {
  OPEN,
  CLOSE,
  SET_PAYLOAD,
  SET_ERROR,
  SET_PAYLOAD_COMPLETED,
  CLEAR
} from "./constant";

export const open = () => ({
  type: OPEN
});

export const close = () => ({
  type: CLOSE
});

export const setPayload = payload => ({
  type: SET_PAYLOAD,
  payload
});

export const setError = payload => ({
  type: SET_ERROR,
  payload
});

export const setPayloadCompleted = payload => ({
  type: SET_PAYLOAD_COMPLETED,
  payload
});

export const clear = () => ({
  type: CLEAR
});
