import * as c from "./constant";

export default (state = { loading: false }, action) => {
  switch (action.type) {
    case c.loading:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
