import rootReducer from "./combineReducers.js";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { init as remoteInit, remote } from "./modules/Remote/remote";
import { loadConfig } from "./modules/Config/action";

const initialState = {
  devToolFunc: (f) => f,
  middleware: [],
};

const configureStore = (config, reducers) => {
  const historyOptions = {
    basename: config.basename ? `/${config.basename}` : "",
  };
  const history =
    config.variant !== "website"
      ? createMemoryHistory(historyOptions)
      : createBrowserHistory(historyOptions);

  remoteInit(
    config.baseURL,
    config.x_cc_app || "",
    config.sessionTracker || false,
    config.sessionDispatchType || "",
    config.sessionUrl || ""
  );

  initialState.middleware.push(thunk.withExtraArgument({ history, remote }));
  initialState.middleware.push(routerMiddleware(history));

  // Development Options for Redux.
  if (config.reduxLogger) {
    initialState.middleware.push(
      createLogger({
        collapsed: true,
        diff: false,
      })
    );

    if ("function" === typeof window.__REDUX_DEVTOOLS_EXTENSION__) {
      initialState.devToolFunc = window.__REDUX_DEVTOOLS_EXTENSION__();
    }
  }

  const store = createStore(
    rootReducer(history, reducers),
    {},
    compose(
      applyMiddleware(...initialState.middleware),
      initialState.devToolFunc
    )
  );

  //load configuration object
  store.dispatch(loadConfig(config));

  return { history, store };
};

export default configureStore;
