import * as c from "./constant";

export const signedIn = () => ({
  type: c.signedIn
});

export const logout = () => ({
  type: c.logout
});

export const accessDenied = () => ({
  type: c.accessDenied
});

export const setServerError = msg => ({
  type: c.serverError,
  payload: msg
});

export const setUser = user => ({
  type: c.user,
  payload: user
});

export const showSessionExpiredModal = val => ({
  type: c.showSessionExpiredModal,
  payload: val
});
