import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";

const Icon = ({ name }) => {
  const nameParts = name.split("-");

  let iconName = nameParts[0];

  for (let i = 1; i < nameParts.length; i++) {
    iconName += nameParts[i][0].toUpperCase() + nameParts[i].slice(1);
  }

  return <FontAwesomeIcon icon={icons[iconName]} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
