import { close, setPayloadCompleted, setError } from "./action";

export const closeWidget = payload => async (dispatch, _, { history }) => {
  dispatch(close());
  dispatch({ type: "RESET" });

  if (payload) {
    if (payload.error) {
      dispatch(setError(payload));
    } else {
      dispatch(setPayloadCompleted(payload));
    }
  }

  history.push("/");
};
