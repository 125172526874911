/**
 * Converts regular string to a template string and uses the params object as it's source of values.
 * @param {string} str
 * @param {Object} params
 */
function interpolate(str, params) {
  const names = Object.keys(params);
  const values = Object.values(params);
  return new Function(...names, `return \`${str}\`;`)(...values);
}

/**
 * Interpolates all string values using the globalObject as it's source of values.
 * @param {Object} object - Current object cursor.
 * @param {Object} globalObject - The root object passed when this function is initially called.
 */
function interpolateObject(object, globalObject) {
  globalObject = globalObject || object;
  if (/(number|boolean|undefined|null|symbol)/.test(typeof object))
    return object;

  if (Array.isArray(object)) return object;
  for (const key in object) {
    const keyType = typeof object[key];
    if (keyType === "string") {
      object[key] = interpolate(object[key], globalObject);
    } else if (Array.isArray(object[key])) {
      object[key] = object[key].map(value => {
        if (typeof value === "string") return interpolate(value, globalObject);
        else return interpolateObject(value, globalObject);
      });
    } else if (keyType === "object") {
      object[key] = interpolateObject(object[key], globalObject);
    }
  }

  return object;
}

export const configBuilder = (name, config) => {
  if (!(name in config)) {
    throw "Name is not defined in configuration.";
  }
  return () => {
    let currentConfig = config[name];
    currentConfig.configurationName = name;

    // look for a well known subdomain in hostname of URL. If match, then blend in additional configuration.
    let matchResults = new RegExp(
      `^(test-${name}|localhost|local|test.cardcash.com)\\.?`,
      "i"
    ).exec(window.location.hostname);

    if (matchResults) {
      let matchedEnv = matchResults[1].toLowerCase();
      Object.assign(currentConfig, config[matchedEnv]);
    }

    interpolateObject(currentConfig);

    return currentConfig;
  };
};

export default configBuilder;
