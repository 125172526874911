import { signedIn, logout, setServerError, setUser } from "./action";

export const doLogin =
  (values, referrer = "/") =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const login = await remote(
        "post",
        "authentication",
        values,
        "do employee login",
        dispatch
      );

      if (login.message) {
        dispatch(setServerError(login.message));
      } else {
        dispatch(signedIn());
        dispatch(setUser(login));
        history.push(referrer.includes("security") ? "/" : referrer);
      }
    } catch (e) {
      console.error(`doLogin Error: ${e.message}`);
    }
  };

export const doLogout =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      await remote(
        "delete",
        "authentication",
        {},
        "do employee logout",
        dispatch
      );

      dispatch(logout());
    } catch (e) {
      console.error(`doLogout Error: ${e.message}`);
    }
  };

export const renewSession =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      const login = await remote(
        "get",
        "authentication",
        {},
        "do employee login check",
        dispatch
      );

      if (!login.message) {
        dispatch(signedIn());
        dispatch(setUser(login));
      }
    } catch (e) {
      console.error(`renewSession Error: ${e.message}`);
    }
  };

export const redirectToLogin =
  () =>
  async (dispatch, getState, { history, remote }) => {
    try {
      dispatch(logout());
      history.push("/security/login", {
        referrer: getState().router.location.pathname,
      });
    } catch (e) {
      console.error(`redirectToLogin Error: ${e.message}`);
    }
  };
